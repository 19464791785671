import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
 
const Image = ({ alt, height, src, width, style }) => (
  <div>
    <LazyLoadImage
      alt={alt}
      height={height}
      src={src} // use normal <img> attributes as props
      width={width}
      style={style}
    />
  </div>
);
 
export default Image;
