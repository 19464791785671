import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import {
  Hero,
  Beneficios,
  Case,
  PricingCards,
} from '../Promotion/components';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';

// FIREBASE
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDF3ynM92Z7Koq3iqT_RUBkvZ9Ulq57YKE',
  authDomain: 'dawntech-website.firebaseapp.com',
  projectId: 'dawntech-website',
  storageBucket: 'dawntech-website.appspot.com',
  messagingSenderId: '107195885524',
  appId: '1:107195885524:web:0c07b5a61b1679f99e9841',
  measurementId: 'G-8TN7P2X575'
};

const app = initializeApp(firebaseConfig);

const Promotion = ({ themeMode }) => {
  const { t } = useTranslation();

  const promo = {
    key: 'promotions.hero',
    image_dark_uri: 'http://dawntech.dev/public/content/plans.png',
    image_light_uri: 'http://dawntech.dev/public/content/plans.png'
  };

  const theme = useTheme();

  const caseEmbaixada = {
    key: 'promotions.cases.foreverliss',
    profileImage: 'https://storage.googleapis.com/dawntech-website.appspot.com/dwntsite/photofl.jpg'
  };

  const [formName, setFormName] = React.useState('');
  const handleFormSubmission = async (form) => {
    setFormName(form.name);
    
    const db = getFirestore(app);

    try {
      await addDoc(collection(db, 'leads'), form);
      setFormSubmittedStatus(true);
    } catch (e) {
      setFormSubmittedStatus(false);
    }
    setFormSubmitted(true);

    setTimeout(() => {
      handleCloseFormSubmitted();
    }, 2000);
  };

  const [openedFormSubmittedModal, setFormSubmitted] = React.useState(false);
  const [formSubmissionSuccess, setFormSubmittedStatus] = React.useState(true);

  const handleCloseFormSubmitted = () => {
    setFormSubmitted(false);
  };

  return (
    <Box>
      <Box bgcolor={theme.palette.alternate.main} position={'relative'}>
        <Container position="relative" zIndex={2}>
          <Hero promotion={promo} themeMode={themeMode}/>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
            height: '35%',
          }}
        >
          <polygon
            fill={theme.palette.background.paper}
            points="0,273 1921,273 1921,0 "
          />
        </Box>
      </Box>
      <Container>
        <Beneficios themeMode={themeMode} />
      </Container>
      <Container>
        <Case content={caseEmbaixada} />
      </Container>
      <Container>
        <Box>
          <Box bgcolor={theme.palette.alternate.main} position={'relative'}>
            <Container position="relative" zIndex={2}>
              <PricingCards onFormSubmission={handleFormSubmission} themeMode={themeMode} />
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1921 273"
              sx={{
                position: 'absolute',
                width: '100%',
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1,
                height: '35%',
              }}
            >
              <polygon
                fill={theme.palette.background.paper}
                points="0,273 1921,273 1921,0 "
              />
            </Box>
          </Box>
        </Box>
      </Container>

      <Dialog
        open={openedFormSubmittedModal}
        onClose={handleCloseFormSubmitted}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              formSubmissionSuccess ?
                t('promotions.form.success', { name: formName }) :
                t('promotions.form.error')
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFormSubmitted} variant="contained" autoFocus>
            Ok!
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

Promotion.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Promotion;
