import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DawntechLogo from '../../../../../../svg/logos/Dawntech';

import { useTranslation } from 'react-i18next';

const SidebarNav = ({ pages, onClose, themeMode }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        mb={3}
        onClick={() => onClose()}
      >
        <Box
          component="a"
          underline="none"
          href="/"
          title="Dawntech"
          height={{ xs: 28, md: 32 }}
          width={116}
        >
          <img src={themeMode === 'light' ? DawntechLogo.cns : DawntechLogo.bns} alt="Dawntech" />
        </Box>

        <Box justifyContent={'flex-end'}>
          <IconButton>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box paddingX={2} paddingBottom={2}>


        <Box>
          {pages.map((item, i) => (
            <Box key={i} marginBottom={4}>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  marginBottom: 1,
                  display: 'block',
                }}
              >
                {t(item.title)}
              </Typography>
              <Grid container spacing={1}>
                {item.pages.map((p, i) => (
                  <Grid item xs={6} key={i}>
                    <Link
                      variant="body2"
                      component={'a'}
                      href={p.href}
                      color={activeLink === p.href ? 'primary' : 'textPrimary'}
                      underline={'none'}
                      sx={{
                        fontWeight: activeLink === p.href ? 600 : 400,
                        '&:hover': {
                          textDecoration: 'none',
                          color: theme.palette.primary.dark,
                        },
                      }}
                      onClick={() => onClose()}
                    >
                      {t(p.title)}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  themeMode: PropTypes.string,
};

export default SidebarNav;
