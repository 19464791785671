import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import TopImageSection from 'components/TopImageSection';
import Container from 'common/Container';

import ProductList from './ProductList';
import products from './products';

export default function ProductsView({ themeMode }) {
  const { t } = useTranslation();

  return (
    <>
      <TopImageSection
        imageURI={require('../../assets/img/products/header.png')}
        padding={25}
        opacity={0.2}
        title={t('products.page.title')}
      ></TopImageSection>

      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'medium',
              }}
              gutterBottom
              color={'secondary'}
              align={'center'}
            >
              {t('products.page.subtitle')}
            </Typography>
            <Box
              component={Typography}
              fontWeight={700}
              variant={'h3'}
              align={'center'}
              sx={{
                whiteSpace: 'pre-line'
              }}
            >
              {t('products.page.description')}
            </Box>
          </Box>

          <Grid container spacing={4}>
            <ProductList products={products} themeMode={themeMode} />
          </Grid>
        </Box>
      </Container>
    </>
  );
}
