import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

const Carrousel = ({ themeMode, items, extraSliderOpts }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const defaultSliderOpts = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: themeMode === 'light' ? 'slick-dots light' : 'slick-dots dark'
  };

  const sliderOpts = { ...defaultSliderOpts, ...extraSliderOpts };

  return (
    <Box maxWidth={'100%'} data-aos={isMd ? 'fade-left' : 'fade-up'}>
      <Slider {...sliderOpts}>
        {
          items.map((item, i) => (
            <Box padding={{ xs: 1, sm: 2 }} key={i}>
              <Box
                component={Card}
                boxShadow={{ xs: 1, sm: 3 }}
                borderRadius={5}
              >
                <CardActionArea href={item.link} target="_blank">
                  <CardMedia
                    component="img"
                    src={item.src}
                  />
                </CardActionArea>
                <CardContent>
                  <Typography variant="h6" color="text.secondary" fontWeight={700} style={{ display: 'flex', alignItems: 'end' }}>
                    {item.title}
                  </Typography>
                </CardContent>
              </Box>
            </Box>
          ))
        }
      </Slider>
    </Box>
  );
};

Carrousel.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Carrousel;
