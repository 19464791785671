import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

function TopImageSection({ imageURI, title, opacity, padding }) {
  const theme = useTheme();

  if (opacity === undefined) {
    opacity = 0.5;
  }

  if (padding === undefined) {
    padding = 15;
  }

  return (
    <Box
      position={'relative'}
      sx={{
        backgroundImage: `url("${imageURI}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&:after': {
          position: 'absolute',
          content: '" "',
          width: '100%',
          height: '100%',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          background: theme.palette.common.black,
          opacity
        },
      }}
    >
      <Container sx={{
        zIndex: 3,
        position: 'relative'
      }}>
        <Container
          sx={{
            marginLeft: '0 !important',
            maxWidth: { md: '50% !important' }
          }}
        >
          <Box>
            <Box>
              <Box pt={padding} mb={padding}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.common.white,
                  }}
                >
                  { title }
                </Typography>
              </Box>
            </Box>
          </Box>
            
        </Container>
      </Container>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        width={'100%'}
        marginBottom={-1}
        position={'relative'}
        zIndex={2}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
}

export default TopImageSection;
