import React from 'react';
import { ReactTyped } from 'react-typed';
import cx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Image from '../components/Image';
import PostCard from '../components/PostCard';
import Carrousel from '../components/Carrousel';
import SectionHeader from '../components/SectionHeader';

import MonitorIcon from '@mui/icons-material/Monitor';
import ArticleIcon from '@mui/icons-material/Article';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import SummarizeIcon from '@mui/icons-material/Summarize';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from 'react-i18next';

import addMetaTags from '../addMetaTags';

const useStyles = makeStyles((themeMode) => ({ //não está funcionando, cai sempre no themeMode not light
  gridItemBox: {
    color: '#FFFFFF',
    display: 'inline-flex',
    padding: 12,
    borderRadius: '100%',
    backgroundColor: themeMode === 'light' ? 'rgb(0, 0, 235)' : '#32484E',
    marginBottom: 10,
    boxShadow: '0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
  },
  sectionIcon: {
    color: '#FFFFFF',
    display: 'inline-flex',
    padding: '1em',
    marginBottom: 24,
    borderRadius: '100%',
    backgroundColor: themeMode === 'light' ? 'rgb(229, 231, 235)' : '#32484E',
    boxShadow: '0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
  }
}));

const businessCases = [
  {
    title: 'Ully | UseCar',
    link: 'https://www.terra.com.br/byte/usecar-dobra-volume-de-aluguel-de-carros-com-uso-de-chatbot,2880cdd4964219ebf3079f4a1d0c679d5vhkp3co.html',
    src: require('../assets/img/cases/case1/cover.png')
  },
  {
    title: 'Liss | Forever Liss',
    link: 'https://business.whatsapp.com/resources/success-stories/forever-liss',
    src: require('../assets/img/cases/case2/cover.png')
  },
  {
    title: 'Coplacana | Chatbot',
    link: 'https://dawntech.dev/plans',
    src: require('../assets/img/coplacana.png')
  }
];

const clientList = [
  // [ alt/title, image location, href link, show on mobile boolean]
  
  ['Ayvens', '/public/clients/ayvens.png', 'https://www.ayvens.com', false],
  ['Bagaggio', '/public/clients/bagaggio.png', 'https://www.youtube.com/shorts/t0GPHxMNPBI', true],
  ['Banco Master', '/public/clients/bancomaster.png', 'https://www.bancomaster.com.br/', false],
  ['Camil', '/public/clients/camil.png', 'https://www.camil.com.br', true],
  ['Consórcio Embracon', '/public/clients/consorcioembracon.png', 'https://www.embracon.com.br/', true],
  ['Engie', '/public/clients/engie.png', 'https://www.engie.com', false],
  ['Gazin', '/public/clients/gazin.png', 'https://www.gazin.com.br', false],
  ['H&M', '/public/clients/hm.png', 'https://www.hm.com', true],
  ['HDI Seguros', '/public/clients/hdi.png', 'https://www.hdiseguros.com.br/', false],
  ['Leroy Merlin', '/public/clients/leroymerlin.png', 'https://www.leroymerlin.com.br', true],
  ['Legrand', '/public/clients/legrand.png', 'https://www.legrand.com', false],
  ['Madesa', '/public/clients/madesa.png', 'https://www.madesa.com', false],
  ['Ultragaz', '/public/clients/ultragaz.png', 'https://www.ultragaz.com.br', true]
];


export default function Index({ themeMode }) {
  const { t } = useTranslation();

  const Styles = useStyles(themeMode);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  addMetaTags({
    title: 'Dawntech',
    description: 'We help companies to speed up their software development process by providing them with software development resources. We also develop software on demand, such as web apps and virtual assistants.',
    url: ''
  }, {
    openGraph: {
      title: 'Dawntech'
    }
  });

  return (
    <>
      <Container>
        <Box sx={{ maxWidth: 'none', position: 'relative', marginTop: '50px', marginBottom: '100px'}} zIndex={2}>
          <Grid container spacing={6} justifyContent="center" alignItems="center">
            <Grid item sm={12} md={7} lg={7} xl={7}>
              <Container maxWidth="md">
                <Box position="relative">
                  <SectionHeader variant="h3">
                    {t('index.header.title')}
                    <br />

                    {t('index.header.typed-prepend')}
                    <br/>
                    <ReactTyped
                      strings={t('index.header.typed-strings', { returnObjects: true })}
                      typeSpeed={80}
                      backSpeed={40}
                      loop={true}
                      smartBackspace={false}
                    />
                  </SectionHeader>

                  <Typography variant="subtitle1" data-aos={'fade-right'}>
                    {t('index.subheader.line1')}
                    <br />
                    {t('index.subheader.line2')}
                  </Typography>
                </Box>
              </Container>
            </Grid>

            <Grid item sm={6} md={5} lg={5} xl={5}>
              <Image
                src={require('../assets/img/pattern.svg').default}
                alt="..."
                width={'80%'}
                height={'auto'}
              ></Image>
            </Grid>
          </Grid>
        </Box>        
      </Container>

      <Box color={themeMode === 'light' ? '#DBE7EB' : 'rgb(23,26,34)'} position="relative" zIndex={2}>
        <svg
          className="svg-decal"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </Box>
      <Box sx={{ backgroundColor: themeMode === 'light' ? '#DBE7EB' : 'rgb(23,26,34)', pb: 28 }} position="relative" zIndex={2}>
        <Container>
          <Grid container spacing={6} justifyContent="center" alignItems="center">
            <Grid item md={6} lg={5} xl={5}>
              <PostCard
                src={require('../assets/img/computer.png')}
                body={t('index.projects.card.body')}
                heading={t('index.projects.card.heading')}
              />
            </Grid>

            <Grid item md={6} lg={7} xl={7} container justifyContent="center" alignItems="stretch" spacing={6} direction="row">
              <Grid item xs={6}>
                <Box className={cx(Styles.gridItemBox)} data-aos="fade-right" data-aos-delay="300">
                  <MonitorIcon />
                </Box>
                <SectionHeader variant="h6">
                  {t('index.projects.types.consultancy.title')}
                </SectionHeader>
                <Typography variant="subtitle1" data-aos="fade-right" data-aos-delay="300">
                  {t('index.projects.types.consultancy.body')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box className={cx(Styles.gridItemBox)} data-aos="fade-right" data-aos-delay="300">
                  <ArticleIcon />
                </Box>
                <SectionHeader variant="h6">
                  {t('index.projects.types.projects.title')}
                </SectionHeader>
                <Typography variant="subtitle1" data-aos="fade-right" data-aos-delay="300">{t('index.projects.types.projects.body')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Box className={cx(Styles.gridItemBox)} data-aos="fade-right" data-aos-delay="300">
                  <AccountTreeIcon />
                </Box>
                <SectionHeader variant="h6">
                  {t('index.projects.types.training.title')}
                </SectionHeader>
                <Typography variant="subtitle1" data-aos="fade-right" data-aos-delay="300">{t('index.projects.types.training.body')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Box className={cx(Styles.gridItemBox)} data-aos="fade-right" data-aos-delay="300">
                  <ChatBubbleIcon />
                </Box>
                <SectionHeader variant="h6">
                  {t('index.projects.types.assistants.title')}
                </SectionHeader>
                <Typography variant="subtitle1" data-aos="fade-right" data-aos-delay="300">{t('index.projects.types.assistants.body')}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={6} justifyContent="center" alignItems="center" pt={24}>
            <Grid item sm={6}>
              <Box className={cx(Styles.sectionIcon)}>
                <SummarizeIcon />
              </Box>

              <SectionHeader>
                {t('index.difference.heading')}
              </SectionHeader>

              <Typography variant="p" data-aos="fade-right">
                {t('index.difference.subheader.line1')}
                <br />
                {t('index.difference.subheader.line2')}
              </Typography>

              <Box>
                <List>
                  <ListItem data-aos="fade-right" data-aos-delay="300">
                    <ListItemAvatar>
                      <Avatar className={cx(Styles.gridItemBox)}>
                        <FingerprintIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t('index.difference.items.personalized')} />
                  </ListItem>

                  <ListItem data-aos="fade-right" data-aos-delay="300">
                    <ListItemAvatar>
                      <Avatar className={cx(Styles.gridItemBox)}>
                        <WorkspacePremiumIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t('index.difference.items.results')} />
                  </ListItem>

                  <ListItem data-aos="fade-right" data-aos-delay="300">
                    <ListItemAvatar>
                      <Avatar className={cx(Styles.gridItemBox)}>
                        <DoneAllIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t('index.difference.items.support')} />
                  </ListItem>
                </List>
              </Box>
            </Grid>

            <Grid item sm={6} data-aos={isMd ? 'fade-in' : 'fade-in'}>
              <Image
                src={require('../assets/img/dawn.gif')}
                alt={t('dawntech')}
                width={'250%'}
                height={'auto'}
                style={{
                  transform: 'scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)',
                  boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
                  borderRadius: '.5em'
                }}
              ></Image>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* CASES */}
      <Box component={Container} position="relative" zIndex={2} mt={-12}>
        <Grid container spacing={4} mt={{ xs: 25, md: -12 }}>
          <Grid item xs={12} md={6}>
            <Box width={1} height="100%" display="flex" alignItems="center">
              <Box>
                <SectionHeader>
                  {t('index.cases.heading')}
                </SectionHeader>
                <Typography
                  variant={'subtitle1'}
                  component={'p'}
                  color={'textSecondary'}
                  data-aos={'fade-right'}
                >
                  {t('index.cases.subheader')}
                </Typography>

                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <Typography variant={'h6'} fontWeight={600} gutterBottom>
                    {t('cases.know-more.heading')}
                  </Typography>

                  <Button component={RouterLink} to="/cases"
                    variant='contained'
                    color='info'
                    fontWeight={700}
                    style={{
                      textTransform: 'uppercase'
                    }}
                  >
                    {t('cases.know-more.button')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width={1} height="100%" display="flex" alignItems="center">
              <Carrousel items={businessCases} themeMode={themeMode} />
            </Box>
          </Grid>
        </Grid>

        <Box mt={20}>
          <Grid container spacing={8}>
            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'center'}
              xs={12}
              md={6}
              data-aos={isMd ? 'fade-left' : 'fade-up'}
            >
              <Box component='iframe' sx={{ aspectRatio: '16/9', width: '100%', height: '100%' }} src="https://player.vimeo.com/video/856033719?background=1&badge=0&autopause=0&player_id=0&app_id=58479" allow="autoplay; fullscreen; picture-in-picture" title=""></Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
                <Typography variant="h3">
                  Blip Trusted Partner
                </Typography>

                <Typography variant="subtitle1">
                  {t('blip.summary')}
                </Typography>

                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <Typography variant={'h6'} fontWeight={600} gutterBottom>
                    {t('partners.know-more.heading')}
                  </Typography>

                  <Button component={RouterLink} to="/partners"
                    variant='contained'
                    fontWeight={700}
                    style={{
                      textTransform: 'uppercase'
                    }}
                  >
                    {t('partners.know-more.button')}
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={12} data-aos={'zoom-in'}>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                }}
                gutterBottom
                color={'textSecondary'}
                align={'center'}
                variant={'h6'}
              >
                {t('index.clients.header')}
              </Typography>

              <Box display="flex" flexWrap="wrap" justifyContent={'center'} mt={6}>
                {
                  clientList.filter(c => isSm ? true : c[3]).map(([clientName, clientImage, clientURL], i) => (
                    <Box
                      maxWidth={110}
                      marginTop={{ xs: 1 }}
                      marginRight={{ xs: 2, sm: 3, md: 4 }}
                      key={i}
                      component="a"
                      href={clientURL}
                      target="_blank"
                    >
                      <Box
                        component="img"
                        height={'100%'}
                        width={'100%'}
                        src={clientImage}
                        alt={clientName}
                        title={clientName}
                        sx={{
                          filter: 'grayscale(1)',
                          transition: 'all .3s ease-in',
                          scale: '1',
                          borderRadius: '50%',
                          zIndex: 9,
                          backgroundColor: themeMode === 'light' ? 'inherit' : 'whitesmoke',
                          padding: themeMode === 'light' ? '0' : '.5em',
                          '&:hover': {
                            transition: 'all .3s ease-in',
                            scale: '1.5',
                            filter: 'grayscale(0)',
                            backgroundColor: 'whitesmoke',
                            position: 'relative',
                            zIndex: 10,
                            padding: themeMode === 'light' ? '0' : '.5em',
                          }
                        }}
                      />
                    </Box>
                  ))
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Container maxWidth="md">
        <Box my={12} py={6}
          component={Card}
          data-aos={'zoom-in'}
        >
          <CardContent>
            <Box textAlign='center'>
              <Typography variant={'h5'} fontWeight={600} gutterBottom>
                {t('index.work.heading')}
              </Typography>

              <Typography variant='subtitle1' gutterBottom>
                {t('index.work.subtitle')}
              </Typography>

              <Box mt={3}>
                <Button component={RouterLink} to="/jobs"
                  variant='contained'
                  fontWeight={700}
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  {t('index.work.action')}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Container>
    </>
  );
}
