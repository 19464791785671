import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import FastForwardIcon from '@mui/icons-material/FastForward';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AddTaskIcon from '@mui/icons-material/AddTask';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import cx from 'clsx';

const useStyles = makeStyles((themeMode) => ({ //não está funcionando, cai sempre no themeMode not light
  icon: {
    color: '#FFFFFF',
    borderRadius: '100%',
    fontSize: 'large',
    width: '60px',
    height: '60px',
    display: 'inline-flex',
    marginBottom: 24,
    backgroundColor: themeMode === 'light' ? 'rgb(229, 231, 235)' : '#32484E',
    boxShadow: '0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
  }
}));

const Beneficios = ({ themeMode = 'light' }) => {
  const Styles = useStyles(themeMode);
  const { t } = useTranslation();

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          {t('promotions.benefits.title')}
        </Typography>
        <Box
          component={Typography}
          sx={{ fontWeight: 700 }}
          variant={'h3'}
          gutterBottom
          align={'center'}
        >
          {t('promotions.benefits.header.line1')}
          <br />
          {t('promotions.benefits.header.line2')}
        </Box>
        <Typography align={'center'} color="textSecondary" variant={'h6'}>
          {t('promotions.benefits.subheader')}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {[
          {
            title: t('promotions.benefits.productivity.title'),
            subtitle: t('promotions.benefits.productivity.subtitle'),
            icon: <ShowChartIcon />,
          },
          {
            title: t('promotions.benefits.optimization.title'),
            subtitle: t('promotions.benefits.optimization.subtitle'),
            icon: <AddTaskIcon />,
          },
          {
            title: t('promotions.benefits.marketing.title'),
            subtitle: t('promotions.benefits.marketing.subtitle'),
            icon: <FastForwardIcon />,
          },
        ].map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box width={1} height={'100%'} data-aos={'fade-up'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Avatar className={cx(Styles.icon)}>
                  {item.icon}
                </Avatar>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <Typography align={'center'} color="textSecondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box marginTop={8}>
        <Grid container spacing={2}>
          {
            t('promotions.benefits.statistics', { returnObjects: true })
              .map((e, i) => (
                <Grid key={i} item xs={12} md={4}>
                  <Typography variant="h3" align={'center'} gutterBottom>
                    <Box fontWeight={600}>
                      <VisibilitySensor
                        onChange={(isVisible) => setViewPortVisibility(isVisible)}
                        delayedCall
                      >
                        <CountUp
                          redraw={false}
                          end={viewPortEntered ? e.title : 0}
                          start={0}
                          suffix={'%'}
                        />
                      </VisibilitySensor>
                    </Box>
                  </Typography>
                  <Typography color="text.secondary" align={'center'} component="p">
                    {e.subtitle}
                  </Typography>
                </Grid>
              ))}
        </Grid>
      </Box>
    </Box>
  );
};

Beneficios.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Beneficios;
