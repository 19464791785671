import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import Link from '@mui/material/Link';
import LeadForm from '../LeadForm';

import ElementCarrousel from '../../../../components/ElementCarrousel';

import { useTranslation } from 'react-i18next';

const pricing = [
  {
    key: 'promotions.plans.start', // lite
    price: {
      currency: 'R$',
      trimestral: 6160,
      semestral: 5544,
      anual: 5544
    }
  },
  {
    key: 'promotions.plans.lite', // business
    price: {
      currency: 'R$',
      trimestral: 7200,
      semestral: 6480,
      anual: 6480
    }
  },
  {
    key: 'promotions.plans.premium+', // enterprise
    price: {
      currency: 'R$',
      trimestral: 12160,
      semestral: 10944,
      anual: 10944
    }
  },
  {
    key: 'promotions.plans.basic', // start
    price: {
      currency: 'R$',
      trimestral: 3840,
      semestral: 3456,
      anual: 3456
    }
  },
];

const PricingCards = ({ onFormSubmission, themeMode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [pricingOption, setPricingOption] = useState('semestral');

  const handleClick = (event, newPricingOption) => {
    if (newPricingOption) {
      setPricingOption(newPricingOption);
    }
  };

  const [openFormModal, createFunctionToCall] = useState(() => () => { });

  const PlanCard = (item, i) => (
    <Box key={i} data-aos={'fade-down-right'} data-aos-delay={500}>
      <Box
        component={Card}
        height={'100%'}
        display={'flex'}
        flexDirection={'column'}
        borderRadius={4}
        mx={0} 
      >
        <Box component={CardContent} padding={4}>
          <Box
            marginBottom={4}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Box
              marginBottom={1}
              display={'flex'}
              width={'100%'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant={'h6'}>
                <Box component={'span'} fontWeight={600}>
                  {t(`${item.key}.title`)}
                </Box>
              </Typography>
              <Box display={'flex'} alignItems={'flex-start'}>
                <Typography variant={'h4'} color={'primary'}>
                  <Box
                    component={'span'}
                    fontWeight={600}
                    marginRight={1 / 2}
                  >
                    {item.price.currency}
                  </Box>
                </Typography>
                <Typography variant={'h3'} color={'primary'}>
                  <Box component={'span'} fontWeight={600}>
                    {
                      pricingOption === 'semestral' ? item.price.semestral :
                        pricingOption === 'trimestral' ? item.price.trimestral :
                          item.price.anual
                    }
                  </Box>
                </Typography>
              </Box>
            </Box>
            <Typography variant={'subtitle2'} color={'textSecondary'} style={{fontWeight: 'bold'}}>
              {t('promotions.plans_view.billedMonthly')}
              <b style={{fontWeight: 'bold', color: 'primary'}}>
                {pricingOption === 'anual'
                  ? t('promotions.plans_view.freeMonth')
                  : ''}
              </b>
            </Typography>
          </Box>
          <Grid container spacing={1}>
            {t(`${item.key}.features`, { returnObjects: true }).map((feature, j) => (
              <Grid item xs={12} key={j}>
                <Typography
                  component={'p'}
                  align={'center'}
                  style={{
                    textDecoration: !feature.isIncluded
                      ? 'line-through'
                      : 'none',
                  }}
                >
                  {feature.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box flexGrow={1} />
        <Box sx={{ textAlign: 'center' }} justifyContent={'center'}>
          { item.detailsURI ?
            <Link href={item.detailsURI} variant="subtitle2" sx={{ textAlign: 'center' }} target="_blank">
              {t('promotions.plans_view.details')}
            </Link>
            : <></>
          }
        </Box>
        <Box
          component={CardActions}
          justifyContent={'center'}
        >
          <Button
            size={'large'}
            variant={item.isHighlighted ? 'contained' : 'outlined'}
            value={item}
            onClick={() => openFormModal(item)}
            data-aos={'zoom-in'}
            data-aos-delay={500}
          >
            {t(`${item.key}.button`)}
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box data-aos={'fade-down'}>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
          whiteSpace={'pre-line'}
        >
          {t('promotions.plans_view.title')}
        </Box>
      </Box>
      <Box>
        <Box display={'flex'} justifyContent={'center'} marginBottom={4}>
          <ToggleButtonGroup
            value={pricingOption}
            exclusive
            onChange={handleClick}
          >
            <ToggleButton
              value="semestral"
              size={'small'}
              sx={{
                backgroundColor:
                  pricingOption === 'semestral'
                    ? `${theme.palette.primary.light} !important`
                    : 'transparent',
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 'medium',
                  color:
                    pricingOption === 'semestral'
                      ? theme.palette.common.white
                      : 'primary',
                }}
              >
                {t('promotions.plans_view.pricingOption.semestral')}
              </Typography>
            </ToggleButton>
            <ToggleButton
              value="anual"
              size={'small'}
              sx={{
                backgroundColor:
                  pricingOption === 'anual'
                    ? `${theme.palette.primary.light} !important`
                    : 'transparent',
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 'medium',
                  color:
                    pricingOption === 'anual'
                      ? theme.palette.common.white
                      : 'primary',
                }}
              >
                {t('promotions.plans_view.pricingOption.anual')}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Grid container>
          <Box width={1} height="100%" display="flex" alignItems="center">
            <ElementCarrousel items={pricing.map((item, i) => PlanCard(item, i))} themeMode={themeMode} extraSliderOpts={{ slidesToShow: 3, infinite: true }}/>
          </Box>

          <LeadForm createFunctionToCall={createFunctionToCall} onFormSubmission={onFormSubmission} pricingOption={pricingOption} />
        </Grid>
      </Box>
    </Box>
  );
};

export default PricingCards;
