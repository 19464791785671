import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SectionHeader = ({ children, variant, fontWeight }) => {
  fontWeight = fontWeight || 700;
  variant = variant || 'h4';

  return (
    <Box
      component={Typography}
      fontWeight={fontWeight}
      variant={variant}
      gutterBottom
      data-aos={'fade-in'}
    >
      {children}
    </Box>
  );
};

export default SectionHeader;
