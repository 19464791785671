// import React from 'react';

export default [
  {
    id: 'product-1',
    logo: require('../../assets/img/products/product-1/logo.png'),
    photos: [
      { src: require('../../assets/img/products/product-1/photo-1.png') },
      { src: require('../../assets/img/products/product-1/photo-2.png') },
      { src: require('../../assets/img/products/product-1/photo-2.png') },
      { src: require('../../assets/img/products/product-1/photo-2.png') },
      { src: require('../../assets/img/products/product-1/photo-2.png') },
      { src: require('../../assets/img/products/product-1/photo-2.png') },
      { src: require('../../assets/img/products/product-1/photo-2.png') },
      { src: require('../../assets/img/products/product-1/photo-3.png') },
    ]
  }
];
