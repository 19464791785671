import React from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

export default [
  {
    media: require('../../assets/img/cases/case_amstel/cover.png'),
    logo: require('../../assets/img/cases/case_amstel/logo.png'),
    case: {
      name: 'amstel',
      links: [['Case @ Último Segundo', 'https://ultimosegundo.ig.com.br/parceiros/adnews/2024-03-18/amstel-estreia-quadro-inedito-i-am-sincerinho-no-mesacast-bbb.html', <AddAPhotoIcon key={0} />], ['Engajamento @ Meio & Mensagem', 'https://www.meioemensagem.com.br/midia/bbb-24-amstel', <AccountBalanceIcon key={1} />]],
      clientURL: 'https://www.amstelbrasil.com',
      images: [['image', require('../../assets/img/cases/case_amstel/1.jpeg')],['image', require('../../assets/img/cases/case_amstel/2.jpeg')],['image', require('../../assets/img/cases/case_amstel/3.jpeg')],['image', require('../../assets/img/cases/case_amstel/4.jpeg')],['image', require('../../assets/img/cases/case_amstel/5.jpeg')],['image', require('../../assets/img/cases/case_amstel/6.jpeg')]],
      channels: [['instagram', 'https://instagram.com/amstelbr']]
    },
    segment: 'beverage',
    category: 'chatbot',
  },
  {
    media: require('../../assets/img/cases/case1/cover.png'),
    logo: require('../../assets/img/cases/case1/logo.png'),
    case: {
      name: 'usecar',
      links: [['Case @ Mobile Time', 'https://www.mobiletime.com.br/noticias/10/01/2023/usercar-dobra-volume-de-aluguel-de-carros-com-uso-de-chatbot', <AccountBalanceIcon key={0} />], ['Case @ Terra', 'https://www.terra.com.br/byte/usecar-dobra-volume-de-aluguel-de-carros-com-uso-de-chatbot,2880cdd4964219ebf3079f4a1d0c679d5vhkp3co.html', <AddAPhotoIcon key={1} />]],
      clientURL: 'https://www.usecarbrasil.com.br/',
      images: [['image', require('../../assets/img/cases/case1/print.png')]],
      channels: [['whatsapp', 'https://whatsapp.com'], ['site']]
    },
    segment: 'auto',
    category: 'chatbot',
  },
  {
    media: require('../../assets/img/cases/case2/cover.png'),
    logo: require('../../assets/img/cases/case2/logo.png'),
    case: {
      name: 'foreverliss',
      clientURL: 'https://www.foreverliss.com.br',
      links: [['Case @ Meta', 'https://business.whatsapp.com/resources/success-stories/forever-liss', <AccountBalanceIcon key={0} />]],
      images: [['image', require('../../assets/img/cases/case2/print.png')]],
      channels: [['whatsapp', 'https://whatsapp.com'], ['instagram'], ['messenger'], ['site']]
    },
    segment: 'cosmetico',
    category: 'chatbot'
  },
  {//images: [['iframe', 'https://wpcoder.co.uk/links/mixkit-man-holding-neon-light-1238-large.mp4'], ['image', require('../../assets/img/cases/case1/image1.png')]],
    media: require('../../assets/img/cases/case4/cover.png'),
    logo: require('../../assets/img/cases/case4/logo.png'),
    case: {
      name: 'bagaggio',
      clientURL: 'https://www.bagaggio.com.br',
      images: [['iframe', 'https://player.vimeo.com/video/926921960?background=1&badge=0&autopause=0&player_id=0&app_id=58479']],
      links: [['+Info', 'https://www.linkedin.com/posts/blipbr_pratodosverem-activity-7135628481748492289-RzXB?utm_source=share&utm_medium=member_desktop', <AccountBalanceIcon key={0} />]],
      channels: [['whatsapp', 'https://whatsapp.com']],
      sliderSettings: {
        autoplay: true,
        autoPlaySpeed: 15000
      }
    },
    segment: 'commerce',
    category: 'chatbot',
  },
  {//images: [['iframe', 'https://wpcoder.co.uk/links/mixkit-man-holding-neon-light-1238-large.mp4'], ['image', require('../../assets/img/cases/case1/image1.png')]],
    media: require('../../assets/img/cases/case6/cover.png'),
    logo: require('../../assets/img/cases/case6/logo.png'),
    case: {
      name: 'engovafter',
      clientURL: 'https://www.engov.com.br/produto/engov-after',
      images: [['image', require('../../assets/img/cases/case6/1.png')]],
      links: [['+Info', 'https://assets.dawntech.dev/content/engovafter.pdf', <AccountBalanceIcon key={0} />]],
      channels: [['whatsapp', 'https://whatsapp.com']]
    },
    segment: 'beverage',
    category: 'chatbot',
  },
  {//images: [['iframe', 'https://wpcoder.co.uk/links/mixkit-man-holding-neon-light-1238-large.mp4'], ['image', require('../../assets/img/cases/case1/image1.png')]],
    media: require('../../assets/img/cases/case5/cover.png'),
    logo: require('../../assets/img/cases/case5/logo.png'),
    case: {
      name: 'lindacasa',
      clientURL: 'https://www.lindacasa.com.br',
      images: [['image', require('../../assets/img/cases/case5/1.png')],['image', require('../../assets/img/cases/case5/2.png')],['image', require('../../assets/img/cases/case5/3.png')]],
      links: [['+Info', 'https://docs.google.com/presentation/d/1xugJdDSjZVlgC90hM7F2a45cjTjwNaS1eKyGyDS8Ei8/edit?usp=sharing', <AccountBalanceIcon key={0} />]],
      channels: [['whatsapp', 'https://wa.me/5516982085091']]
    },
    segment: 'commerce',
    category: 'chatbot',
  }, // gupy, madesa, engov
  {
    media: require('../../assets/img/cases/case_markselectrical/cover.jpg'),
    logo: require('../../assets/img/cases/case_markselectrical/logo.png'),
    case: {
      name: 'markselectrical',
      clientURL: 'https://markselectrical.co.uk/',
      images: [['image', require('../../assets/img/cases/case_markselectrical/1.png')],['image', require('../../assets/img/cases/case_markselectrical/2.png')]],
      links: [],
      channels: [['whatsapp', 'https://api.whatsapp.com/send/?phone=447984040252&text&type=phone_number&app_absent=0&wame_ctl=1']]
    },
    segment: 'commerce',
    category: 'chatbot'
  }
];
